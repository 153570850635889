.react-flow__node-block {
    cursor: pointer;
    font-size: 10px;
    width: 180px;
    color: #222;
}
/*Handle style*/
.react-flow__node-block .react-flow__handle,
.react-flow__node-end .react-flow__handle,
.react-flow__node-intent .react-flow__handle,
.react-flow__node-custom .react-flow__handle,
.react-flow__node-flow .react-flow__handle,
.react-flow__node-form .react-flow__handle {
    background-color: #b4c9d9 !important;
}
.react-flow__node-start .react-flow__handle {
    background-color: #43a047 !important;
}

.react-flow__handle {
    z-index: 100 !important;
    min-width: 2px !important;
    width: 4px !important;
    height: 8px !important;
    background: inherit !important;
    border-radius: 2px !important;
}
.react-flow__node-end .react-flow__handle{
    background: #2C2B2A !important;
}
.magic-node > .react-flow__handle, .magic-node > * >.react-flow__handle {
    background: #8491F7 !important;
}
.react-flow__handle.static {
    z-index: 100 !important;
    min-width: 2px !important;
    width: 4px !important;
    height: 4px !important;
    border-radius: 50% !important;
    display: flex;
    flex-direction: row;
}
.react-flow__handle.static.next:hover:after {
    content: "next";
    font-size: 9px;
    color: #727c86;
    background: #FAFAFA;
    margin-left: 10px;
    margin-top: -4px;
}
.react-flow__handle.static.no-match:hover:after {
    content: "no-match";
    font-size: 9px;
    white-space: nowrap;
    color: #727c86;
    background: #FAFAFA;
    margin-left: 10px;
    margin-top: -4px;
}
.react-flow__handle.static.fail:hover:after {
    content: "fail";
    font-size: 9px;
    color: #727c86;
    background: #FAFAFA;
    margin-left: 10px;
    margin-top: -4px;
}

.react-flow__node.circle {
    border-radius: 50%;
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
}

.react-flow__node.annotation {
    border-radius: 0;
    text-align: left;
    background: white;
    border: none;
    line-height: 1.4;
    width: 225px;
    box-shadow: 0 4px 6px -1px rgb(0 0 0 / 15%), 0 2px 4px -1px rgb(0 0 0 / 8%);
}

.react-flow__node.annotation .react-flow__handle {
    display: none;
}

.custom-node__header {
    padding: 8px 10px;
    /*border-bottom: 1px solid #e2e8f0;*/
}

.custom-node__body {
    /*padding: 10px;*/
    background: rgba(255,255,255, 1) !important;
}

.custom-node__select {
    position: relative;
    /*margin-bottom: 10px;*/
}

.custom-node__select select {
    width: 100%;
    margin-top: 5px;
    font-size: 10px;
}

a[href='https://reactflow.dev'] {
    display: none !important;
}

.react-flow__node {
    cursor: pointer;
}

.magic-cursor .react-flow__pane, .magic-cursor .react-flow__node {
    cursor: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGNsYXNzPSJpY29uIGljb24tdGFibGVyIGljb24tdGFibGVyLXdhbmQiIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0IiBzdHJva2Utd2lkdGg9IjEuNSIgc3Ryb2tlPSIjMjUyNTI1IiBmaWxsPSJub25lIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiPjxwYXRoIHN0cm9rZT0ibm9uZSIgZD0iTTAgMGgyNHYyNEgweiIgZmlsbD0ibm9uZSIvPjxwYXRoIGQ9Ik02IDIxbDE1IC0xNWwtMyAtM2wtMTUgMTVsMyAzIiAvPjxwYXRoIGQ9Ik0xNSA2bDMgMyIgLz48cGF0aCBkPSJNOSAzYTIgMiAwIDAgMCAyIDJhMiAyIDAgMCAwIC0yIDJhMiAyIDAgMCAwIC0yIC0yYTIgMiAwIDAgMCAyIC0yIiAvPjxwYXRoIGQ9Ik0xOSAxM2EyIDIgMCAwIDAgMiAyYTIgMiAwIDAgMCAtMiAyYTIgMiAwIDAgMCAtMiAtMmEyIDIgMCAwIDAgMiAtMiIgLz48L3N2Zz4=") 16 16, pointer !important;
}

.magic-node,
.react-flow__node.selected > .magic-node
{
    border-color: #8491F7 !important;
    background: linear-gradient(-0.8turn, #8491F7AA, #8491F7FF) !important;
}

.magic-cursor .react-flow__node:hover {
    animation: shake 1.5s infinite linear !important;
    border-color: rgba(215, 232, 244, 0.5) !important;
    border-radius: 24px !important;
    background: linear-gradient(0.8turn,  rgba(93, 180, 179, 0.5),  rgba(215, 232, 244, 0.5)) !important;

}

@keyframes shake {
    0%, 100% {
        left: 0;
        top: 0;
    }
    10%, 30%, 50%, 70%, 90% {
        left: -1px;
        top: 1px;
    }
    20%, 40%, 60%, 80% {
        left: 1px;
        top: -1px;
    }
}