@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300&family=Montserrat:wght@300&display=swap');

body {
  margin: 0;
  font-family: "Manrope", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

.sql-color {
  color: rgb(239,119,19);
}

#root, body {
  font-family: "Manrope", sans-serif;
  width: 100vw !important;
  height: 100vh !important;
  overflow: hidden;
}

.hide-icon > .hidden-checkbox {
  display: none;
}

#login-page {
  width: 100vw;
  height: 100vh;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  overflow-x: hidden;
}

*::-webkit-scrollbar {
  width: 6px !important;
  height: 6px !important;
}

button {
  border-radius: 0 !important;
  border: 0 !important
}

div::-webkit-scrollbar-thumb {
  border-radius: 4px;
  -webkit-box-shadow: inset 0 0 6px #adacac;
  background-color: #adacac;
  cursor: pointer;
}

.image-item:hover {
  background: rgba(0, 0, 0, 0.5) !important;
}

.border-shadow {
  box-shadow: rgba(0, 0, 0, 0.04) 0px 5px 22px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}

.profile-link {
  border-bottom: 3px solid transparent !important;
  color: #000 !important;
  border-radius: 0 !important;
}
.profile-link.active {
  border-bottom: 3px solid #673ab7 !important;
  color: #673ab7 !important;
  border-radius: 0 !important;
}
.nav-link.active {
  font-weight: bold !important;
  color: #efa2a9ff !important;
}

.custom-node {
  background: #d3e5f4;
  color: #252525 !important;
}

.darkness > * {
  pointer-events: none !important;
}

.darkness .react-flow__node:not(.selected) {
  filter: brightness(80%);
  pointer-events: none !important;
}

.react-flow__node.selected > .custom-node {
  background-color: #b4c9d9 !important;
  border-color: #b4c9d9;
  box-shadow: 0px 0px 20px 2px rgba(142,162,176,0.5);
  -webkit-box-shadow: 0px 0px 20px 2px rgba(142,162,176,0.5);
  -moz-box-shadow: 0px 0px 20px 2px rgba(142,162,176,0.5);
  color: #4e5860 !important;
  filter: none;
}
.react-flow__node.selected > .flow-node {
  background-color: #c4def5 !important;
  border-color: #c4def5;
  box-shadow: 0px 0px 20px 2px rgb(196, 222, 245, 0.81);
  -webkit-box-shadow: 0px 0px 20px 2px rgb(196, 222, 245, 0.81);
  -moz-box-shadow: 0px 0px 20px 2px rgb(196, 222, 245, 0.81);
  color: #4e5860 !important;
  filter: none;
}
.react-flow__node.selected > .start-node {
  background-color: green !important;
  border-color: #4caf50;
  box-shadow: 0px 0px 20px 2px rgba(76,175,80,0.81);
  -webkit-box-shadow: 0px 0px 20px 2px rgba(76,175,80,0.81);
  -moz-box-shadow: 0px 0px 20px 2px rgba(76,175,80,0.81);
  filter: none !important;
  position: absolute !important;
}

.end-node {
  background: #2C2B2ABB !important;
  border-color: #2C2B2ABB !important;
}
.transfer-call-node {
  background: rgba(44, 117, 188, 0.73) !important;
  border-color: rgba(44, 117, 188, 0.73) !important;
}
.react-flow__node.selected > .end-node {
  background-color: #2C2B2AFF !important;
  border-color: #2C2B2AFF !important;
  box-shadow: 0px 0px 20px 2px rgba(33,33,33,0.61);
  -webkit-box-shadow: 0px 0px 20px 2px rgba(33,33,33,0.61);
  -moz-box-shadow: 0px 0px 20px 2px rgba(33,33,33,0.61);
  filter: none !important;
  position: absolute !important;
}
.react-flow__node.selected > .transfer-call-node {
  background: rgba(44, 117, 188, 0.83) !important;
  border-color: rgba(44, 117, 188, 0.83) !important;
  box-shadow: 0px 0px 20px 2px rgba(44, 117, 188, 0.61);
  -webkit-box-shadow: 0px 0px 20px 2px rgba(44, 117, 188,0.61);
  -moz-box-shadow: 0px 0px 20px 2px rgba(44, 117, 188,0.61);
  filter: none !important;
  position: absolute !important;
}
.react-flow__node.selected > .intent-node {
  background-color: #0d47a1 !important;
  border-color: #0d47a1;
  box-shadow: 0px 0px 20px 2px rgba(13, 71, 161, 0.81);
  -webkit-box-shadow: 0px 0px 20px 2px rgba(13, 71, 161, 0.81);
  -moz-box-shadow: 0px 0px 20px 2px rgba(13, 71, 161, 0.81);
  filter: none !important;
  position: absolute !important;
}

@keyframes shake {
  0% { transform: translate(0, 0); }
  25% { transform: translate(-3px, 3px); }
  50% { transform: translate(3px, -3px); }
  75% { transform: translate(-3px, 3px); }
  100% { transform: translate(0, 0); }
}

.shake {
  animation: shake 0.4s ease-in-out infinite;
}

.side-link {
  color: #2C2B2A !important;
}
.side-link[aria-disabled=true] {
  color: rgba(151, 153, 154, 0.5) !important;
}

.side-link.active {
  background: rgba(215, 232, 244, 0.5) !important;
  border-radius: 8px !important;
  color: #2C2B2A !important;
}

.side-link:hover {
  background: rgba(215, 232, 244, 0.5) !important;
  border-radius: 8px !important;
  color: #2C2B2A !important;
}
.side-item:hover {
  background: rgba(215, 232, 244, 0.3) !important;
  border-radius: 8px !important;
  color: #2C2B2A !important;
}

.kb-table:not(:has(tr.selected)) tr {
  /* Styles for tables without any rows with class "selected" */
  opacity: 1 !important;
  background: transparent !important;
}
.kb-table tbody tr.selected {
  /* Styles for tables without any rows with class "selected" */
  opacity: 1 !important;
  background: transparent !important;
}
.kb-table tbody tr:not(.selected) {
  opacity: 0.4;
  background: rgba(244, 244, 244, 0.7);
}


@keyframes live-url {
  0% {
    color: rgba(0, 200, 0, 1);
  }
  50% {
    color: rgba(0, 255, 0, 1);
  }
  100% {
    color: rgba(0, 200, 0, 1);
  }
}

.live-green > * {
  color: rgba(0, 200, 0, 1);
  animation: live-url 2s infinite !important;
  -moz-animation: live-url 2s infinite !important; /* corrected from 'recoding' */
  -o-animation: live-url 2s infinite !important; /* corrected from 'recoding' */
  -webkit-animation: live-url 2s infinite !important; /* corrected from 'recoding' */
}
@keyframes recording {
  0% {
    color: rgba(200, 0, 0, 1);
  }
  50% {
    color: rgba(255, 0, 0, 1);
  }
  100% {
    color: rgba(200, 0, 0, 1);
  }
}

.live-red > * {
  color: rgba(200, 0, 0, 1);
  animation: recording 2s infinite !important;
  -moz-animation: recording 2s infinite !important; /* corrected from 'recoding' */
  -o-animation: recording 2s infinite !important; /* corrected from 'recoding' */
  -webkit-animation: recording 2s infinite !important; /* corrected from 'recoding' */
}

.pulse-container {
  width: 100%;
  height: 10dvh;
  position: relative;
  display: inline-block;
}

.pulse-container::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 80px;  /* Adjust for larger effect */
  height: 80px;
  background: radial-gradient(circle, rgba(0, 255, 0, 0.6) 20%, rgba(0, 255, 0, 0) 70%);
  border-radius: 50%;
  transform: translate(-50%, -50%) scale(1);
  z-index: -1;  /* Keeps glow behind the text */
  animation: pulseGlow 2s infinite ease-in-out;
}

@keyframes pulseGlow {
  0% {
    transform: translate(-50%, -50%) scale(1.1);
    opacity: 1;
  }
  50% {
    transform: translate(-50%, -50%) scale(2);
    opacity: 0.6;
  }
  100% {
    transform: translate(-50%, -50%) scale(1.1);
    opacity: 1;
  }
}

.pulse-text {
  position: relative; /* Ensures text stays above the glow */
  color: #252525; /* Strong green color */
  font-weight: bold;
}

